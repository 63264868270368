import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'assets/css/vendors/lazy-load-image-opacity.css';

/**
 * @param src
 * @param alt
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const LazyImg = ({ src, alt, className }) => (
  <LazyLoadImage effect='opacity' src={src} alt={alt} className={className} />
);

export default LazyImg;
